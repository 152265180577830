<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="subdomain">{{ $t('management_subdomain.business_name') }}</label>
                            <multiselect
                                id="uuid"
                                v-model="uuid"
                                :placeholder="$t('management_subdomain.business_name')"
                                label="subdomain"
                                track-by="id"
                                :options="companies"
                                :multiple="true"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getCompanies($event)">
                            </multiselect>
                        </div>

                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{$t('search')}}</span>
                                </span>
                            </button>
                            <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{$t('reset_search')}}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                
                <!-- <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                </v-server-table> -->

                <div class="row justify-content-end p-4">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">

                        <download-excel
                                class="dropdown-item"
                                :fetch="invoiceExport"
                                :fields="json_fields_invoice"
                                :name="$t('management_subdomain.reports_companies_invoices')+'.xls'">
                            <i class="la la-file-excel"></i>{{ $t('excel')}}
                        </download-excel>
                        <!-- <button class="dropdown-item" @click="printData('companiesInvoices')">
                            <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                        </button> -->
                    </b-dropdown>
                </div>
                <table id="companiesInvoices" class="table table-bordered text-center">
                    <thead>
                    <tr>
                        <th>{{ $t('management_subdomain.business_name_subdomain') }}</th>
                        <th>{{ $t('management_subdomain.plan_name') }}</th>
                        <th>{{ $t('management_subdomain.sales_count') }}</th>
                        <th>{{ $t('management_subdomain.sales_total') }}</th>
                        <th>{{ $t('management_subdomain.purchases_count') }}</th>
                        <th>{{ $t('management_subdomain.purchases_total') }}</th>
                        <th>{{ $t('management_subdomain.users_count') }}</th>
                        <th>{{ $t('management_subdomain.customers_count') }}</th>
                        <th>{{ $t('management_subdomain.suppliers_count') }}</th>
                        <th>{{ $t('management_subdomain.payment_count') }}</th>
                        <th>{{ $t('management_subdomain.payment_total') }}</th>
                        <th>{{ $t('management_subdomain.items_count') }}</th>
                        <th>{{ $t('management_subdomain.sales_last_sales_date') }}</th>
                        <th>{{ $t('management_subdomain.purchases_last_purchases_date') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                        <template v-if="dataList.length > 0" >
                            <tr v-for="(data, index) in dataList" :key="index">
                                <td>{{ data.business_name }}</td>
                                <td>{{ data.plan }}</td>
                                <td>{{ data.sales_count }}</td>
                                <td>{{ data.sales_total }}</td>
                                <td>{{ data.purchases_count }}</td>
                                <td>{{ data.purchases_total }}</td>
                                <td>{{ data.users_count }}</td>
                                <td>{{ data.customers_count }}</td>
                                <td>{{ data.suppliers_count }}</td>
                                <td>{{ data.payment_count }}</td>
                                <td>{{ data.payment_total }}</td>
                                <td>{{ data.items_count }}</td>
                                <td>{{ data.sales_last_sales_date }}</td>
                                <td>{{ data.purchases_last_purchases_date }}</td>
                            </tr>
                        </template>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{{ $t('total') }}</th>
                            <th>{{ '--' }}</th>
                            <th>{{ dataTotal.sum_count_sales }}</th>
                            <th>{{ dataTotal.sum_sales_total }}</th>
                            <th>{{ dataTotal.sum_purchases_count }}</th>
                            <th>{{ dataTotal.sum_purchases_total }}</th>
                            <th>{{ dataTotal.sum_users_count }}</th>
                            <th>{{ dataTotal.sum_customers_count }}</th>
                            <th>{{ dataTotal.sum_suppliers_count }}</th>
                            <th>{{ dataTotal.sum_payments_count }}</th>
                            <th>{{ dataTotal.sum_payments_total }}</th>
                            <th>{{ dataTotal.sum_items_count }}</th>
                            <th>{{ '--' }}</th>
                            <th>{{ '--' }}</th>
                        </tr>
                    </tfoot>
                </table>
            <div class="col-12 text-center" v-if="page">
                <button class="btn btn-warning" @click="loadMore">
                    <p class="mb-0">
                        <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                        {{$t('load_more')}}
                    </p>
                </button>
            </div>
            </div>
        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        components: {},
        data() {
            return {
                mainRoute: '/subscription/management_subdomain/invoices',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,

                filters: {
                    uuid : [],
                },
               
                // columns: [
                //             'business_name','plan', 'sales_count','sales_total', 'purchases_count',
                //             'purchases_total','users_count','customers_count','suppliers_count',
                //             'payment_count','payment_total','items_count',
                //             'sales_last_sales_date','purchases_last_purchases_date'
                //         ],

                users: [],
                companies: [],
                validation: [],
                dataList : [],
                user : null,
                subdomain: null,
                uuid: [],
                dataTotal : [],
                page:1,

            }
        },
        computed: {
            // options: function () {
            //     let that = this;
            //     return {
            //         texts: {
            //             loadingError: that.$t('Something_went_wrong'),
            //             filter: "",
            //             limit: that.$t('records'),
            //             filterBy: that.$t('Filter') + ' {column}',
            //             count: ' ',
            //             filterPlaceholder: that.$t('Search_query'),
            //             loading: that.$t('Loading') + "...",
            //         },
            //         headings: {
            //             // id: '#',
            //             business_name: that.$t('management_subdomain.business_name_subdomain'),
            //             plan: that.$t('management_subdomain.plan_name'),

            //             sales_count: that.$t('management_subdomain.sales_count'),
            //             sales_total: that.$t('management_subdomain.sales_total'),
            //             purchases_count: that.$t('management_subdomain.purchases_count'),
            //             purchases_total: that.$t('management_subdomain.purchases_total'),
            //             users_count: that.$t('management_subdomain.users_count'),
            //             customers_count: that.$t('management_subdomain.customers_count'),
            //             suppliers_count: that.$t('management_subdomain.suppliers_count'),
            //             payment_count: that.$t('management_subdomain.payment_count'),
            //             payment_total: that.$t('management_subdomain.payment_total'),
            //             items_count: that.$t('management_subdomain.items_count'),
            //             sales_last_sales_date: that.$t('management_subdomain.sales_last_sales_date'),
            //             purchases_last_purchases_date: that.$t('management_subdomain.purchases_last_purchases_date'),
            //         },
            //         sortable: [],
            //         filterByColumn: false,
            //         filterable: false,
            //         customFilters: [{
            //             name: 'alphabet',

            //         }],
            //         orderBy: {'column': 'created_at'},

            //         alwaysShowPerPageSelect: true,
            //         perPage: 10,
            //         pagination: {chunk: 5, dropdown: false},
            //         skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
            //         sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
            //         perPageValues: [5, 10, 15, 25],

            //         requestFunction(data) {
                        
            //             let _params = {
            //                 ascending: 0,
            //                 byColumn: data.byColumn,
            //                 limit: data.limit,
            //                 orderBy: data.orderBy,
            //                 page: data.page,
            //                 filter: data.query,
            //                 ...that.filters,
            //             }

            //             return ApiService.query(that.mainRoute, {..._params}).catch(function (error) {
            //                 console.error(error)
            //             })

            //         },
            //         responseAdapter(resp) {
            //             that.dataList = resp.data.data.data;
            //             that.dataTotal = resp.data.data.data_total;
            //             return {
            //                 data: resp.data.data.data,
            //                 dataTotal: resp.data.data.data_total,
            //                 count: 1,
            //             }
            //         }
            //     }

            // },
            json_fields_invoice: function () {
                let fields = {};
                
                fields[this.$t('management_subdomain.business_name_subdomain')] = 'business_name';
                fields[this.$t('management_subdomain.plan_name')] = 'plan';
                fields[this.$t('management_subdomain.sales_count')] = 'sales_count';
                fields[this.$t('management_subdomain.sales_total')] = 'sales_total';
                fields[this.$t('management_subdomain.purchases_count')] = 'purchases_count';
                fields[this.$t('management_subdomain.purchases_total')] = 'purchases_total';
                fields[this.$t('management_subdomain.users_count')] = 'users_count';
                fields[this.$t('management_subdomain.customers_count')] = 'customers_count';
                fields[this.$t('management_subdomain.suppliers_count')] = 'suppliers_count';
                fields[this.$t('management_subdomain.payment_count')] = 'payment_count';
                fields[this.$t('management_subdomain.payment_total')] = 'payment_total';
                fields[this.$t('management_subdomain.items_count')] = 'items_count';
                fields[this.$t('management_subdomain.sales_last_sales_date')] = 'sales_last_sales_date';
                fields[this.$t('management_subdomain.purchases_last_purchases_date')] = 'purchases_last_purchases_date';

                return fields;
            },
        },
        watch: {
            uuid: function (val) {
                
                this.filters.uuid = this.filters.uuid.filter(uuid => val.some(row => row.uuid === uuid));

                val.forEach(row => {
                    if (!this.filters.uuid.includes(row.uuid)) 
                        this.filters.uuid.push(row.uuid);
                });
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.invoices")}]);
            this.getCompanies();
            this.getData();
        },
        methods: {

            invoiceExport() {
                let _footer = {
                        business_name: this.$t('total'),
                        plan: '--',
                        sales_count: this.dataTotal.sum_count_sales,
                        sales_total: this.dataTotal.sum_sales_total,
                        purchases_count: this.dataTotal.sum_purchases_count,
                        purchases_total: this.dataTotal.sum_purchases_total,
                        users_count: this.dataTotal.sum_users_count,
                        customers_count: this.dataTotal.sum_customers_count,
                        suppliers_count: this.dataTotal.sum_suppliers_count,
                        payment_count: this.dataTotal.sum_payments_count,
                        payment_total: this.dataTotal.sum_payments_total,
                        items_count: this.dataTotal.sum_items_count,
                        sales_last_sales_date: '--',
                        purchases_last_purchases_date: '--',
                    };
                let _data = [...this.dataList];
                _data.push(_footer);
                return _data;
            },

            printData(tableId) {
                let divToPrint = document.getElementById(tableId);
                let divTitleToPrint = document.getElementById(tableId + 'Title');
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },

            async getCompanies(filter) {
                if (filter && filter.length >= 3) {
                    await ApiService.get(`${this.mainRouteDependency}/tenants/list`, {params: {filter: filter}}).then((response) => {
                       this.companies = response.data.data;
                    });
                } else {
                    this.companies = [];
                }
            },

            loadMore() {
                this.page = this.page ? (this.page + 1) : 1;
                this.getData();
            },
            getData(){
                let queryParams = new URLSearchParams();

                if (this.filters.uuid.length) {
                    this.filters.uuid.forEach(uuid => {
                        queryParams.append('uuid[]', uuid);
                    });
                }

                ApiService.get(this.mainRoute,{params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => { 
                    this.dataList = response.data.data.data;
                    this.dataTotal = response.data.data.data_total;
                });
            },
            getFetch() {
                // this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.getData();
            },
            resetFilter() {
                this.uuid = [];
                this.companies = [];
                this.filters.uuid = [];
                this.getData();
            },
        },
    };
</script>
